import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
import Note from "../../components/text-decorations/note";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`On a recent project, I am using `}<a parentName="p" {...{
        "href": "https://django-ninja.rest-framework.com"
      }}>{`Django Ninja`}</a>{` instead of `}<a parentName="p" {...{
        "href": "https://www.django-rest-framework.org/"
      }}>{`Django Rest Framework`}</a>{` mainly because I wanted to have better control over typing and also because it's slightly easier to implement.`}</p>
    <p>{`When writing the tests and the API for the project, it was clear that the ManyToMany relationship between the two models failed the validation.`}</p>
    <h2 {...{
      "id": "the-code",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-code",
        "aria-label": "the code permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Code`}</h2>
    <p>{`These are the models. As you can see, we have a HabitCategory and a Habit. Each Habit can have a Category.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`class HabitCategory(models.Model):
    title = models.CharField(max_length=255)
    icon = models.ImageField(null=True, blank=True)


class Habit(models.Model):
    created_at = models.DateTimeField(blank=False, auto_now_add=True)
    user = models.ForeignKey(User, on_delete=models.CASCADE)
    title = models.CharField(max_length=1000)
    post = models.TextField(blank=True)
    category = models.ManyToManyField(HabitCategory, blank=True)
    visibility = models.CharField(
        max_length=10,
        choices=HabitVisibilityChoices.choices,
        default=HabitVisibilityChoices.PUBLIC,
    )
`}</code></pre>
    <p>{`The test itself is pretty basic. It makes a `}<inlineCode parentName="p">{`GET`}</inlineCode>{` request and tries to assert that the representation of the object is correct. It might be worth mentioning that I have created fixtures to create a test object, user and Django client.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`def test_get_habit(habit_with_updates_and_interactions, django_client):
    habit_id = habit_with_updates_and_interactions.id
    response = django_client.get(reverse("api-v1:habit", kwargs={"pk": habit_id}))

    assert response.status_code == 200
    habit = response.json()
    assert habit.title == "Think about my parents"
`}</code></pre>
    <p>{`Finally, let's have a look at the view and the Schema:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`class HabitSchema(ModelSchema):
    user: UserSchema
    category: List[str]
    total_updates: int
    total_thumbs_up: int
    total_comments: int

    class Config:
        model = Habit
        model_fields = [
            "id",
            "created_at",
            "user",
            "title",
            "post",
            "visibility",
            "category",
        ]

@router.get(url_name="habit", path="/habit/{pk}", response=HabitSchema)
def get_habit(request: HttpRequest, pk: int):
    return get_object_or_404(Habit, pk=pk, user=request.user)
`}</code></pre>
    <p>{`I am using a `}<inlineCode parentName="p">{`UserSchema`}</inlineCode>{` and a `}<inlineCode parentName="p">{`HabitCategorySchema`}</inlineCode>{`, which should give me just the `}<inlineCode parentName="p">{`user.username`}</inlineCode>{` and the `}<inlineCode parentName="p">{`habit_category.title`}</inlineCode>{`. But when the tests run, the following validation error pops up:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`pydantic.error_wrappers.ValidationError: 1 validation error for NinjaResponseSchema
response -> category -> 0
  field required (type=value_error.missing)
`}</code></pre>
    <p>{`Okay, so it seems that the category doesn't have a title. This isn't surprising since the category has a ManyToMany relationship.`}</p>
    <h2 {...{
      "id": "addressing-the-problem",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#addressing-the-problem",
        "aria-label": "addressing the problem permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Addressing the problem`}</h2>
    <p>{`We can remove the `}<inlineCode parentName="p">{`category: HabitCategorySchema`}</inlineCode>{` mention in the Schema, and the tests will pass, but the category isn't what we expect.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`# Updated Schema without category
class HabitSchema(ModelSchema):
    user: UserSchema
    total_updates: int
    total_thumbs_up: int
    total_comments: int

    class Config:
        model = Habit
        model_fields = [
            "id",
            "created_at",
            "user",
            "title",
            "post",
            "visibility",
            "category",
        ]

# Response from the API
{
    'id': 5,
    'created_at': '2022-08-16T09:11:48.041358+00:00',
    'user': {
        'username': 'batman'
    },
    'title': 'Think about my parents',
    'post': 'They were awesome',
    'visibility': 'private',
    'category': [14],
    'total_updates': 2,
    'total_thumbs_up': 3,
    'total_comments': 2
}
`}</code></pre>
    <p>{`As you can see, `}<inlineCode parentName="p">{`category`}</inlineCode>{` is now available, but it's returning a list and the HabitCategory id. But what we want is the title from this specific category.`}</p>
    <p>{`The trick is to write a static method to fetch the category title.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`class HabitSchema(ModelSchema):
    user: UserSchema
    category: List[str]
    total_updates: int
    total_thumbs_up: int
    total_comments: int

    class Config:
        model = Habit
        model_fields = [
            "id",
            "created_at",
            "user",
            "title",
            "post",
            "visibility",
            "category",
        ]

    @staticmethod
    def resolve_category(obj):
        return [category.title for category in obj.category.all()]
`}</code></pre>
    <p>{`This will iterate over all the object categories and return the title for us. Note that we also need to add `}<inlineCode parentName="p">{`category: List[str]`}</inlineCode>{` to the Schema. When we run the tests, the API will now return:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`{
    'id': 5,
    'created_at': '2022-08-16T09:23:19.361886+00:00',
    'user': {
        'username': 'batman'
    },
    'title': 'Think about my parents',
    'post': 'They were awesome',
    'visibility': 'private',
    'category': [
        'Family'
    ],
    'total_updates': 2,
    'total_thumbs_up': 3,
    'total_comments': 2
}
`}</code></pre>
    <h2 {...{
      "id": "other-alternatives",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#other-alternatives",
        "aria-label": "other alternatives permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Other alternatives`}</h2>
    <p>{`This article wouldn't be completed if I didn't show other ways to achieve a similar result. But you could use either of these alternatives and get good results. In my case, I wanted the `}<inlineCode parentName="p">{`category`}</inlineCode>{` to be a list of strings.`}</p>
    <h3 {...{
      "id": "using-the-habitcategoryschema",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#using-the-habitcategoryschema",
        "aria-label": "using the habitcategoryschema permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using the HabitCategorySchema`}</h3>
    <p>{`We could pass the HabitCategorySchema to the Habit Schema, and it would work. For example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`
class HabitSchema(ModelSchema):
    created_at: datetime.datetime
    user: UserSchema
    title: str
    post: str
    category: List[HabitCategorySchema]
    visibility: str
    total_updates: int
    total_thumbs_up: int
    total_comments: int

    class Config:
        model = Habit
        model_fields = [
            "id",
            "created_at",
            "user",
            "title",
            "post",
            "visibility",
            "category",
        ]

# The API returns:
{
    'id': 5,
    'created_at': '2022-08-16T09:28:25.872980+00:00',
    'user': {
        'username': 'batman'
    },
    'title': 'Think about my parents',
    'post': 'They were awesome',
    'visibility': 'private',
    'category': [
        {'title': 'Family'}
    ],
    'total_updates': 2,
    'total_thumbs_up': 3,
    'total_comments': 2
}
`}</code></pre>
    <p>{`The response is a bit more complex, but maybe that's okay for your use case.`}</p>
    <h2 {...{
      "id": "using-a-one-linerish",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#using-a-one-linerish",
        "aria-label": "using a one linerish permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using a one-liner(ish)`}</h2>
    <p>{`Depending on your model, you may be able to use the `}<inlineCode parentName="p">{`create_schema`}</inlineCode>{` function to make Django Ninja create the Schema for us. Under the hood, the `}<inlineCode parentName="p">{`ModelSchema`}</inlineCode>{` class uses this function to generate the Schema, and we can also remove a lot of our code and use the`}</p>
    <Note mdxType="Note">
  You should add the <code>exclude</code> or <code>fields</code> params when
  using this approach. Otherwise, <code>create_schema</code> will return all
  model fields, which can expose unwanted data such as hashed passwords.
    </Note>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`HabitSchema = create_schema(Habit, depth=1)
`}</code></pre>
    <p>{`I couldn't make `}<inlineCode parentName="p">{`create_schema`}</inlineCode>{` work for this particular case and decided not to spend more time on this approach.`}</p>
    <p>{`I hope this helps!`}</p>
    <hr></hr>
    <p><strong parentName="p">{`References:`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://django-ninja.rest-framework.com/"
        }}>{`Django Ninja`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://django-ninja.rest-framework.com/guides/response/django-pydantic-create-schema/"
        }}>{`Docs: using create_schema`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/vitalik/django-ninja/issues/456#issuecomment-1145307851"
        }}>{`Issue: Flatten Many to Many`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      